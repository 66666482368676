// @flow

import * as React from 'react';
import polyglot from 'i18n';
import { withRouter } from 'react-router-dom';
import { extractHash, recursiveFind, tableName } from 'utils/utils';
import searchRequestBuilder from 'utils/searchApiHelpers';
import type { RouterHistory, TableState, UserData } from 'types';
import ErrorBoundary from './ErrorBoundary';

type State = {
  rolleValg: [],
  fylkeFilter: string,
  kommuneFilter: string,
  organisasjonFilter: string,
  departementFilter: string,
  ledigeLokalerFraDatoFilter: string,
  ledigeLokalerTilDatoFilter: string,
  tempRole: string,
  aktivFilter?: boolean | string,
  isDep: boolean,
  isSysAdm: boolean,
  objektTypeFilter?: string,
};

type Props = {
  userData: UserData,
  table: TableState,
  actions: {
    toggleListItem?: ({}) => void,
    updateTable(string, {}): void,
    displayAlert?: ({}) => void,
    searchRequest({}): void,
    toggleOrg?: ({}) => void,
  },
  history: RouterHistory,
  location: {
    query: {},
    search: string,
    pathname: string,
  },
  toggleFlag?: boolean,
  data: {
    result: Array<{}>,
    idArray: Array<{}>,
  },
  type: string,
  render({
    onTextChange(): {},
    onSubmit(): {},
    userData: {
      tilganger: [],
    },
    handleFilterChange(): {},
    handleChecked(): {},
    handleTableClick(): {},
    sortTable(): {},
    changePage(): {},
    changeShown(): {},
    ...Props,
    ...State,
  }): React.Node,
};

class GenericList extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    const {
      userData,
      table: {
        roles,
        filters: {
          fylke = {},
          kommune = {},
          organisasjon = {},
          departement = {},
          ledigeLokalerFraDato = {},
          ledigeLokalerTilDato = {},
          rapporteringStatus = {},
        },
      },
    } = props;
    this.state = {
      rolleValg: [],
      fylkeFilter: fylke.value,
      kommuneFilter: kommune.value,
      organisasjonFilter: organisasjon.value,
      departementFilter: departement.value,
      ledigeLokalerFraDatoFilter: ledigeLokalerFraDato.value,
      ledigeLokalerFraTilFilter: ledigeLokalerTilDato.value,
      rapporteringStatusFilter: rapporteringStatus.value,
      tempRole: roles,
      isDep: userData.organisasjon
        ? userData.organisasjon.organisasjonstype === 'DEPARTEMENT'
        : false,
      isSysAdm: Array.isArray(userData.roles)
        ? userData.roles.indexOf('ROLE_SYSTEMFORVALTER') > -1
        : false,
    };
  }

  componentDidMount() {
    const { location, actions, type } = this.props;
    const query = extractHash(location.search);
    if (query.page) {
      actions.updateTable(tableName(type), {
        page: parseInt(query.page, 10) || 0,
      });
    } else {
      this.sendSearchAction();
    }
  }

  componentDidUpdate(prevProps) {
    const { location: prevLocation, table: prevTable } = prevProps;
    const { location, table, actions, toggleFlag, type } = this.props;
    const query = extractHash(location.search);
    const prevQuery = extractHash(prevLocation.search);

    if (JSON.stringify(table) !== JSON.stringify(prevTable)) {
      this.sendSearchAction();
    } else if (query.page !== prevQuery.page) {
      // page got updated in url, update in state
      actions.updateTable(tableName(type), {
        page: parseInt(query.page, 10) || 0,
      });
    } else if (toggleFlag && !prevProps.toggleFlag) {
      // Update table when list item is toggled

      actions.updateTable(tableName(type), {});
      if (type === 'organization')
        actions.displayAlert({
          kind: 'success',
          text: polyglot.t('alert.updateSuccess'),
        });
    }
  }

  // fieldValueObject is an object on the form of {field: {value: value}, field: {value: value}}
  onMultiFilterChange = fieldValueObject => {
    const allowedFieldValues = ['visMine', 'visLedige', 'visAktive'];

    for (const field of Object.keys(fieldValueObject)) {
      if (!allowedFieldValues.includes(field)) {
        console.warn('An invalid filter-field value was passed!');
        return;
      }
    }

    const {
      type,
      actions: { updateTable },
      table: { filters },
    } = this.props;

    const currentValues = {
      visMine: {
        value: filters.visMine.value,
      },
      visLedige: {
        value: filters.visLedige.value,
      },
      visAktive: {
        value: filters.visAktive.value,
      },
      visUtgaatte: {
        value: filters.visUtgaatte.value,
      },
    };

    updateTable(tableName(type), {
      filters: {
        ...currentValues,
        ...fieldValueObject,
      },
    });
  };

  onFilterChange = ({ field, value }) => {
    const {
      type,
      actions: { updateTable },
      table: { filters },
    } = this.props;
    switch (field) {
      case 'visMine': {
        updateTable(tableName(type), {
          filters: {
            visMine: {
              value,
            },
            visLedige: {
              value: filters.visLedige.value,
            },
            visAktive: {
              value: filters.visAktive.value,
            },
            visUtgaatte: {
              value: filters.visUtgaatte.value,
            },
          },
        });
        break;
      }
      case 'visLedige': {
        updateTable(tableName(type), {
          filters: {
            visMine: {
              value: filters.visMine.value,
            },
            visLedige: {
              value,
            },
            visAktive: {
              value: filters.visAktive.value,
            },
            visUtgaatte: {
              value: filters.visUtgaatte.value,
            },
          },
        });
        break;
      }
      case 'visAktive': {
        updateTable(tableName(type), {
          filters: {
            visMine: {
              value: filters.visMine.value,
            },
            visLedige: {
              value: filters.visLedige.value,
            },
            visAktive: {
              value,
            },
            visUtgaatte: {
              value: filters.visUtgaatte.value,
            },
          },
        });
        break;
      }
      case 'visUtgaatte': {
        updateTable(tableName(type), {
          filters: {
            visMine: {
              value: filters.visMine.value,
            },
            visLedige: {
              value: filters.visLedige.value,
            },
            visAktive: {
              value: filters.visAktive.value,
            },
            visUtgaatte: {
              value,
            },
          },
        });
        break;
      }
      default:
        this.setState({ [`${field}Filter`]: value });
        break;
    }
  };

  handleTableClick = e => {
    const {
      data,
      history: { push },
      type,
    } = this.props;
    const { id, path } = data.idArray[e];
    if (type === 'matrikkelAlert') {
      this.changeReadStatus(id, true);
    }
    push(path);
  };

  changeReadStatus = (id, status) => {
    const { actions } = this.props;
    actions.toggleListItem({ id, status });
  };

  changePage = e => {
    const { history, location } = this.props;
    history.push({
      pathname: location.pathname,
      search: `?page=${e}`,
    });
  };

  changeShown = (e, type) => {
    this.props.actions.updateTable(tableName(type), {
      shown: e,
    });
  };

  sortTable = e => {
    // check if column is sortable first
    const newSort = recursiveFind(e.target, 'cellIndex');
    const {
      table: { sortCol, sortAsc },
      actions,
      type,
    } = this.props;
    actions.updateTable(tableName(type), {
      sortAsc: sortCol === newSort ? !sortAsc : true,
      sortCol: newSort,
    });
  };

  filterTextChange = e => {
    const { actions, type, location, history } = this.props;
    actions.updateTable(tableName(type), {
      query: e.target.value,
      page: 0, // reset page since we updated filter
    });
    if (location.search)
      history.replace({
        pathname: location.pathname,
        search: ``,
      });
  };

  handleChecked = (e, i) => {
    const { type, data, actions } = this.props;
    if (type === 'user') {
      e.preventDefault();
    } else {
      const { id } = data.idArray[i];
      const status = e.target.checked;
      const message = status
        ? polyglot.t('warning.organizationActivate')
        : polyglot.t('warning.organizationDeactivate');
      if (confirm(message)) {
        console.log(id, status);
        actions.toggleOrg({ id, status });
      } else {
        e.preventDefault();
      }
    }
  };

  submitFilter = e => {
    const { actions, history, location, type, table } = this.props;
    if (e) e.preventDefault();
    const {
      departementFilter,
      fylkeFilter,
      kommuneFilter,
      organisasjonFilter,
      aktivFilter,
      objektTypeFilter,
      ledigeLokalerFraDatoFilter,
      ledigeLokalerTilDatoFilter,
      rapporteringStatusFilter,
    } = this.state;

    actions.updateTable(tableName(type), {
      filters: {
        ...table.filters,
        kommune: {
          value: kommuneFilter,
        },
        fylke: {
          value: fylkeFilter,
        },
        organisasjon: {
          value: parseInt(organisasjonFilter, 10),
        },
        departement: {
          value: parseInt(departementFilter, 10),
        },
        ledigeLokalerFraDato: { value: ledigeLokalerFraDatoFilter },
        ledigeLokalerTilDato: { value: ledigeLokalerTilDatoFilter },
        rapporteringStatusNy: { value: rapporteringStatusFilter },
      },
      aktiv: aktivFilter,
      page: 0, // reset page since we updated filter
      objektTypeFilter,
    });

    history.replace({
      pathname: location.pathname,
      search: ``,
    });
  };

  sendSearchAction = () => {
    const { actions, userData, type, table } = this.props;
    actions.searchRequest(
      searchRequestBuilder({
        type,
        table,
        userData,
      }),
    );
  };

  render() {
    const { render } = this.props;
    return (
      <ErrorBoundary>
        {render({
          onTextChange: this.filterTextChange,
          onSubmit: this.submitFilter,
          handleFilterChange: this.onFilterChange,
          handleMultiFilterChange: this.onMultiFilterChange,
          handleChecked: this.handleChecked,
          handleTableClick: this.handleTableClick,
          sortTable: this.sortTable,
          changePage: this.changePage,
          changeShown: this.changeShown,
          ...this.props,
          ...this.state,
        })}
      </ErrorBoundary>
    );
  }
}

export default withRouter(GenericList);
