import { createSelector } from 'reselect';
import mapData, { apiFields } from 'utils/mapApiData';

const getSearchFromState = state => state.search.data;

export default (type, userData?) =>
  createSelector([getSearchFromState], data => ({
    ...data,
    result: data.result.map(rowItem => [
      ...mapData(
        rowItem,
        apiFields[type].searchTable
          .filter(
            cellItem =>
              userData && userData.isPublic ? !cellItem.sensitive : true,
          )
          .map(cellItem => cellItem.field),
      ),
    ]),
    idArray: data.result.map(row => {
      if (type === 'user')
        return {
          id: row.uuid,
          path: `/d/admin/brukere/${row.uuid}`,
        };
      if (type === 'matrikkelAlert')
        return {
          id: row._links.self,
          path: `/d${row._links.object}`,
        };
      if (type === 'auditlog') {
        return {
          id: row._links.self.split('/')[2],
          path:
            row.objecttype === 'user'
              ? `/d/admin/brukere/${row.objektlink.replace('/user/', '')}`
              : `/d${row.objektlink}`,
        };
      }
      if (type === 'organization')
        return {
          id: row.organisasjonsnummer,
          navn: row.navn,
        };
      if (type === 'prosjekt') {
        return { path: `/d/prosjekt/${row.uuid}`, id: row.uuid };
      }
      if (type === 'melding') {
        return {
          id: row.id,
          path: `/d/admin/melding/${row.id}`,
        };
      }
      return { path: `/d${row._links.self}`, id: row._links.self };
    }),
  }));
